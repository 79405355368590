import { api, apiUrl } from "./../../api/index";
import { LoginData } from "./login-types";

const loginUrl = `${apiUrl}/auth/login`;

export const login = (data: LoginData) => {
  return fetch(loginUrl, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const verifyEmail = (code: string) => {
  return fetch(`${apiUrl}/verifications/email`, {
    method: "PUT",
    body: JSON.stringify({ code }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const verifyEmailSent = () => {
  return api(`${apiUrl}/verifications/email/sent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const verifyEmailSentWithParam = (email: string) => {
  return api(`${apiUrl}/verifications/email/sent_with_param`, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
