import { User } from "@devsplan/wealthclub-shared";
import { createEvent } from "@cobuildlab/react-simple-state";

export const OnFetchMe = createEvent<User>();
export const OnFetchMeError = createEvent();

export const OnFetchMeBasic = createEvent<User>();
export const OnFetchMeBasicError = createEvent();

export const OnSetActiveSystem = createEvent();
export const OnSetActiveSystemError = createEvent();

export const OnSetActiveCrypto = createEvent();
export const OnSetActiveCryptoError = createEvent();

export const OnSetActiveCryptoSystem = createEvent();
export const OnSetActiveCryptoSystemError = createEvent();
