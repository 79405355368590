import i18n from "../i18n/i18n";
import { CircleIntegorragate } from "../components/Icon";

export const API_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

export const WCC_API_URL = process.env.REACT_APP_WCC_API_URL;

export const WCC_API_KEY = process.env.REACT_APP_WCC_API_KEY;

export const PAYPAL_CLIENT_ID =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX
    : process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE;

export const API_URL_NODE = process.env.REACT_APP_API_URL_NODE;

export const HOME_PAGE_URL = "https://wealthclub.org/home";

export const LOGIN_PAGE_URL = "https://wealthclub.org/login";

export const LOGOUT_PAGE_URL = "https://wealthclub.org/logout";

export const INDEX_ROUTE = "/";

export const OLD_AFFILIATION_ROUTE = "/i/:id";

export const HOME_ROUTE = "/home";

export const RANKING_ROUTE = "/ranking";

export const LOGIN_ROUTE = "/login";

export const SIGN_UP_ROUTE = "/register";

export const SIGN_UP_ROUTE_SECONDARY = "/signup";

export const DASHBOARD_ROUTE = "/dashboard";

export const REFERRALS_ROUTE = "/referrals";

export const SETTINGS_ROUTE = "/admin";

export const INFO_ROUTE = "/info";

export const DOWNLOADS_ROUTE = "/downloads";

export const TUTORIALS_ROUTE = "/tutorials";

export const NOTIFICATIONS_ROUTE = "/notifications";

export const FORGOT_ROUTE = "/forgot-password";

export const RESET_ROUTE = "/reset-password";

export const CYCLES_ROUTE = "/cycles";

export const GOLD_ROUTE = "/gold";

export const GOLD_MATRIX_ROUTE = "/gold_matrix";

export const GOLD_MATRIX_ROUTE_ID = "/gold_matrix/:id";

export const GOLD_ROUTE_ID = "/gold/:id";

export const TERMS_AND_CONDITIONS = "/terms-and-conditions";

export const FREQUENTLY_ASKED_QUESTIONS_ROUTE = "/faq";

export const PRIVACY_POLICY = "/privacy-policy";

export const PAYPAL_STATUS_ROUTE = "/paypal";

export const SAFE_ROUTE = "/safe";

export const TICKETS_ROUTE = "/tickets";

export const CRYPTO_ROUTE = "/crypto";

export const VERIFY_EMAIL_ROUTE = "/verify-email";

export const REQUEST_CARD_ROUTE = "/request-card";

export const CRYPTO_WITHDRAW_ROUTE = "/crypto-withdraw";

export const CRYPTO_DEPOSIT_ROUTE = "/crypto-deposit";

export const AUTH_ROUTE = "/auth/:token";

export const LOGOUT_ROUTE = "/logout";

export const FEEDBACK_ROUTE = "/feedback";

export const TOKEN_KEY = "@wealthclub-app/token";

export const DEVICE_SESSION_ID = "@wealthclub-app/device_session_id";

export const REFRESH_TOKEN_KEY = "@wealthclub-app/refresh_token";

export const EMAIL_KEY = "@wealthclub-app/email";

export const LANG_KEY = "@wealthclub-app/lang";

export const VIDEO_CHECK_KEY = "@wealthclub-app/video_check";

export const PRIMARY_COLOR = "#F0CB65";

export const YELLOW_LIGHT_COLOR = "#F5E973";

export const BLUE_COLOR = "#02225c";

export const YELLOW_COLOR = "#FAE067";

export const SECONDARY_COLOR = "#F6A566";

export const GOLD_COLOR = "#F1B044";

export const WHITE_COLOR = "#FDF2D6";

export const RED_COLOR = "#F54509";

export const GREEN_COLOR = "#06A967";

export const PAYPAL_BUTTONS: { [key: string]: string } = {
  "25": "5SK6MTJ7NC6XY",
  "50": "7YHT6M8KN54L6",
  "100": "TDA3QVH69SY4U",
  "250": "3M5J8TUT9FNP4",
  "500": "NDXZCH33CHTQS",
  "1000": "KRJY8YE8Q4PNS",
  "2000": "78WCEBMMPAB5E",
  "3000": "RP9T63DNA82MA",
  "4000": "E73LKECGERS24",
  "5000": "29GRWZ8S4ZH62",
  "10000": "5HUAK6BSJ25N8",
  "20000": "L8EVUP26C7S6U",
  "30000": "ZME24UHKSHQ6U",
  "40000": "2HDYJTAAVVB6A",
  "50000": "AADY2AVGFX6QS",
  "100000": "YKH4U45R5RY2Y",
};

export const FAQ = [
  {
    title: "¿Qué es Wealth Club?",
    paragraph:
      "Wealth Club® Es un Sistema Global de Inversiones y Desarrollo creado por algunos de los transformadores y líderes de la industria más éxitosos del mundo para ayudar a personas como tu a ganar dinero. Nuestra misión es simple: ayudarte a aumentar tus ingresos, obtener más libertad y así poder diseñar tu vida ideal.",
    icon: CircleIntegorragate,
  },
];

export const BUTTONS_AMOUNT = [
  500, 1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000, 50000, 100000,
];

export const CRYPTO_AMOUNTS: { [key: string]: number[] } = {
  bitcoin: [
    0.00097, 0.00195, 0.0039, 0.00781, 0.01562, 0.03125, 0.0625, 0.125, 0.25,
    0.5, 1, 2,
  ],
  eth: [
    0.0122, 0.0024, 0.0488, 0.0976, 0.1953, 0.3906, 0.7812, 1.5625, 3.125, 6.25,
    12.5, 25,
  ],
  usdt: [
    500, 1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000, 50000,
    100000,
  ],
};

export const BUTTONS_AMOUNT_DEPOSIT = [
  25, 50, 100, 250, 500, 1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000,
  40000, 50000, 100000,
];

export const CYCLES_LENGTH = 6;

export const LANGS = ["en", "es", "fr", "sc", "pg", "rs", "cn"];

export const GOLD_BUTTONS = [
  25, 50, 100, 250, 500, 750, 1000, 2500, 5000, 7500, 10000, 25000, 50000,
  75000, 100000,
];

export const GOLD_BUTTONS_BTC = [
  0.0004, 0.0009, 0.0019, 0.0039, 0.0078, 0.0156, 0.0312, 0.0625, 0.125, 0.25,
  0.5, 1, 2, 3, 4,
];

export const GOLD_BUTTONS_USDT = [
  25, 50, 100, 250, 500, 750, 1000, 2500, 5000, 7500, 10000, 25000, 50000,
  75000, 100000,
];

export const GOLD_BUTTONS_ETH = [
  0.003, 0.0061, 0.0122, 0.0244, 0.0488, 0.097, 0.195, 0.39, 0.781, 1.562,
  3.125, 6.25, 12.5, 25, 50,
];

export const GOLD_CRYPTO_BUTTONS: { [key: string]: number[] } = {
  bitcoin: GOLD_BUTTONS_BTC,
  eth: GOLD_BUTTONS_ETH,
  usdt: GOLD_BUTTONS_USDT,
};

export const GOLD_ARRAY = [
  [0],
  [0, 1],
  [0, 1, 2, 3],
  [0, 1, 2, 3, 4, 5, 6, 7],
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
];

export const GOLD_NODE_SIZES: { [key: number]: string } = [
  "lg",
  "md",
  "sm",
  "xs",
];

export const MATRIX_NODE_TYPE = [
  "SOCIO PERSONAL",
  "DERRAME INFERIOR",
  "DERRAME SUPERIOR",
  "SALTO DE SOCIO",
  "CUENTA CORPORATIVA",
  "PAGO AL SUPERIOR",
];

export const GOLD_ONE_ARRAY = [
  [0],
  [0, 1],
  [0, 1, 2, 3],
  [0, 1, 2, 3, 4, 5, 6, 7],
];

export const IS_MOBILE = "(max-width: 556px)";

export const IS_TABLET = "(max-width: 768px)";

export const PAYMENT_METHODS = [
  i18n.t("Safe"),
  i18n.t("Cash"),
  i18n.t("PayPal"),
  i18n.t("Cryptocurrencies"),
];

export const MATRIX_NODE_TYPE_COLORS = [
  YELLOW_COLOR,
  SECONDARY_COLOR,
  WHITE_COLOR,
  GREEN_COLOR,
  RED_COLOR,
  BLUE_COLOR,
];

export const TELEGRAM_GROUPS: { [key: string]: string } = {
  es: "https://t.me/joinchat/eNCwpJL9rKwyMDA5",
  en: "https://t.me/joinchat/ciwIjDaArHhiMzM5",
  fr: "https://t.me/joinchat/ciwIjDaArHhiMzM5",
  sc: "https://t.me/joinchat/ciwIjDaArHhiMzM5",
  pg: "https://t.me/joinchat/ciwIjDaArHhiMzM5",
  cn: "https://t.me/joinchat/ciwIjDaArHhiMzM5",
  rs: "https://t.me/joinchat/ciwIjDaArHhiMzM5",
};

export const LANDING_TELEGRAM_GROUPS: { [key: string]: string } = {
  es: "https://t.me/wccesp",
  en: "https://t.me/wcceng",
  fr: "https://t.me/wcceng",
  sc: "https://t.me/wcceng",
  pg: "https://t.me/wcceng",
  cn: "https://t.me/wcceng",
  rs: "https://t.me/wcceng",
};

export const IG_GROUPS: { [key: string]: string } = {
  es: "https://www.instagram.com/wealthclubvip/",
  en: "https://www.instagram.com/wealthclubelite/",
  fr: "https://www.instagram.com/wealthclubelite/",
  sc: "https://www.instagram.com/wealthclubelite/",
  pg: "https://www.instagram.com/wealthclubelite/",
  cn: "https://www.instagram.com/wealthclubelite/",
  rs: "https://www.instagram.com/wealthclubelite/",
};

export const MAX_DEVELOPMENTS: { [key: string]: object } = {
  "6": {
    max_dev: 15,
    last_push: 60,
  },
  "12": {
    max_dev: 30,
    last_push: 90,
  },
  "18": {
    max_dev: 30,
    last_push: 120,
  },
  "24": {
    max_dev: 60,
    last_push: 180,
  },
};

export const GOLD_SUFFIX: { [key: string]: string } = {
  bitcoin: "BTC",
  usdt: "USDT",
  eth: "ETH",
};

// Ambassadors

export const MAX_AMBASSADORS = 1000;

export const GOLD_BUTTONS_AMBASSADOR = [
  {
    amount: 25,
    quota: 1000,
    min_referrals: 25,
    gold_referrals: 0,
    level: 1,
  },
  {
    amount: 50,
    quota: 500,
    min_referrals: 25,
    gold_referrals: 5,
    level: 1,
  },
  {
    amount: 100,
    quota: 400,
    min_referrals: 25,
    gold_referrals: 15,
    level: 1,
  },
  {
    amount: 250,
    quota: 300,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 500,
    quota: 250,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 750,
    quota: 200,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 1000,
    quota: 150,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 2500,
    quota: 100,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 5000,
    quota: 75,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 7500,
    quota: 50,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 10000,
    quota: 25,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 25000,
    quota: 20,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 50000,
    quota: 15,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 75000,
    quota: 10,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 100000,
    quota: 5,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
];
export const GOLD_BUTTONS_AMBASSADOR_BITCOIN = [
  {
    amount: 0.0004,
    quota: 1000,
    min_referrals: 25,
    gold_referrals: 0,
    level: 1,
  },
  {
    amount: 0.0009,
    quota: 500,
    min_referrals: 25,
    gold_referrals: 5,
    level: 1,
  },
  {
    amount: 0.0019,
    quota: 400,
    min_referrals: 25,
    gold_referrals: 15,
    level: 1,
  },
  {
    amount: 0.0039,
    quota: 300,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 0.0078,
    quota: 250,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 0.0156,
    quota: 200,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 0.0312,
    quota: 150,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 0.0625,
    quota: 100,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 0.125,
    quota: 75,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 0.25,
    quota: 50,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 0.5,
    quota: 25,
    min_referrals: 0,
    gold_referrals: 0,
    level: 25,
  },
  {
    amount: 1,
    quota: 20,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 2,
    quota: 15,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 3,
    quota: 10,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
  {
    amount: 4,
    quota: 5,
    min_referrals: 0,
    gold_referrals: 0,
    level: 0,
  },
];

export const DELAY_LAZY = 1000;
