import { notification } from "antd";
import { CryptoBalance } from "./types";
import { OnFetchMeBasic } from "./../api/me/me-events";
import {
  AMBASSADORS_ENDPOINT,
  BITCOIN_ADDRESS_ENDPOINT,
  BITCOIN_API_INFO,
  COIN_PAYMENTS_ENDPOINT,
  DEVICE_SESSIONS_ENDPOINT,
  FEEDBACKS_ENDPOINT,
  PAYMENTS_ENDPOINT,
  VALIDATIONS_EMAILS_ENDPOINT,
  WCC_RESET_PASSWORD_ENDPOINT,
} from "./endpoints";
import { getTokens } from "./utils";
import {
  CoinPayment,
  PaymentTransaction,
  PaymentTransactionData,
  User,
} from "@devsplan/wealthclub-shared";
import { apiAxios, apiUrl } from "../api";
import axios from "axios";
import { WCC_API_KEY } from "./constants";

export const createBitcoinAddress = async () => {
  const userInfo = OnFetchMeBasic.get();

  return fetch(`${BITCOIN_ADDRESS_ENDPOINT}/${userInfo?.id}`, {
    method: "POST",
    // @ts-ignore
    headers: {
      "x-api-token": getTokens().token,
    },
  });
};

export const fetchBitcoinWalletBalance = async (
  userInfo: User
): Promise<CryptoBalance> => {
  return new Promise((resolve, reject) => {
    if (userInfo) {
      let balance = 0;
      let balanceDeposit = 0;
      let earnings = 0;

      userInfo.crypto_balance_transactions
        .filter((cb) => cb.status === 1)
        .forEach((cb) => {
          balance += cb.balance;

          if (cb.type === "deposito") {
            balanceDeposit += cb.balance;
          }

          if (cb.type === "ganado gold") {
            earnings += cb.balance;
          }
        });

      resolve({ balance, balanceDeposit, earnings });
    } else {
      reject({ balance: 0, balanceDeposit: 0, earnings: 0 });
    }
  });
};

/**
 * Funcion para revisar si el api de Bitcoin esta funcionando.
 */
export const checkApiBitcoinIsWorking = async (): Promise<boolean> => {
  let isWorking = true;
  let response;

  try {
    response = await fetch(BITCOIN_API_INFO);
  } catch (error) {
    console.log(error);
    isWorking = false;
  }

  if (response) {
    const json = await response.json();

    isWorking = json.insightWorking;
  }

  if (!isWorking) {
    notification.error({
      duration: 8,
      message:
        "Lo sentimos, nuestra red Bitcoin no se encuentra disponible en este momento, por favor, intentelo de nuevo mas tarde.",
    });
  }

  return isWorking;
};

export const createValidationCode = async (data: {
  user_id: number;
  details: string;
}) => {
  return apiAxios().post(`${apiUrl}${VALIDATIONS_EMAILS_ENDPOINT}/code`, data);
};

export const validateCode = async (code: string) => {
  return apiAxios().post(
    `${apiUrl}${VALIDATIONS_EMAILS_ENDPOINT}/code/validate`,
    {
      code,
    }
  );
};

export const createAmbassadors = async (user_id: number, crypto: string) => {
  return apiAxios().post(`${apiUrl}${AMBASSADORS_ENDPOINT}`, {
    user_id,
    crypto,
  });
};

export const fetchAmbassadorsCount = async (
  amount: number,
  crypto: string
): Promise<number> => {
  return apiAxios()
    .post(`${apiUrl}${AMBASSADORS_ENDPOINT}/count`, {
      amount,
      crypto,
    })
    .then((res) => res.data);
};

export const createCoinPayments = async (data: CoinPayment) => {
  return apiAxios()
    .post(`${apiUrl}${COIN_PAYMENTS_ENDPOINT}`, data)
    .then((res) => res.data);
};

export const createPaymentsTransaction = async (
  data: PaymentTransactionData
): Promise<PaymentTransaction> => {
  return apiAxios()
    .post(`${PAYMENTS_ENDPOINT}/transaction`, data)
    .then((res) => res.data);
};

export const getDeviceSession = async () => {
  const token = getTokens().token;
  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return axios
    .post(`${DEVICE_SESSIONS_ENDPOINT}/get`, { time_zone, token })
    .then((res) => res.data);
};

export const createDeviceSession = async (data: {
  email: string;
  from: string;
  password: string;
}) => {
  const token = getTokens().token;
  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return axios
    .post(DEVICE_SESSIONS_ENDPOINT, { time_zone, token, ...data })
    .then((res) => res.data);
};

export const deleteDeviceSession = async (id: number) => {
  return axios
    .delete(`${DEVICE_SESSIONS_ENDPOINT}/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      console.log(error);
    });
};

export const wccResetPassword = async (data: {
  email: string;
  password: string;
  token: string;
}) => {
  // @ts-ignore
  const params = new URLSearchParams({
    ...data,
    secret: WCC_API_KEY,
  }).toString();

  return axios
    .post(`${WCC_RESET_PASSWORD_ENDPOINT}?${params}`)
    .then((res) => res.data);
};

export const createFeedback = async (data: {
  full_name: string;
  text: string;
  user_id: number;
}) => {
  return axios
    .post(`${apiUrl}${FEEDBACKS_ENDPOINT}`, data)
    .then((res) => res.data)
    .catch((error) => {
      console.log(error);
    });
};
