import { apiUrl, api } from "../";

const url = `${apiUrl}/auth/user`;
const urlBasic = `${apiUrl}/auth/user_basic`;

const usersUrl = `${apiUrl}/users`;

export const fetchMe = () => {
  return api(url, {
    method: "GET",
  });
};

export const fetchMeBasic = () => {
  return api(urlBasic, {
    method: "GET",
  });
};

export const setActiveSystem = (active_system: string) => {
  return api(`${usersUrl}/active_system`, {
    method: "POST",
    body: JSON.stringify({ active_system }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const setActiveCrypto = (active_crypto: string) => {
  return api(`${usersUrl}/active_crypto`, {
    method: "POST",
    body: JSON.stringify({ active_crypto }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const setActiveCryptoSystem = (active_crypto_system: string) => {
  return api(`${usersUrl}/active_crypto_system`, {
    method: "POST",
    body: JSON.stringify({ active_crypto_system }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const logoutMe = () => {
  return api(`${apiUrl}/auth/logout`, {
    method: "GET",
  });
};
