import { OnFetchMe } from "../../api/me/me-events";
import { api, apiUrl } from "./../../api/index";

const notificationsUrl = `${apiUrl}/notifications`;

export const updateNotification = (id: number, status: number) => {
  return api(`${notificationsUrl}/${id}`, {
    method: "PUT",
    body: JSON.stringify({ status }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchNotifications = () => {
  return api(notificationsUrl, {
    method: "GET",
  });
};

export const fetchReadAllNotifications = () => {
  return api(`${notificationsUrl}/read/all`, {
    method: "GET",
  });
};

export const readMassiveNotification = (notificationId: number) => {
  const userInfo = OnFetchMe.get();

  if (userInfo) {
    return api(`${notificationsUrl}/massive`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userInfo.id,
        notification_id: notificationId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
};
