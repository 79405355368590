import axios from "axios";
import { getTokens } from "../shared/utils";
import { API_URL } from "./../shared/constants";

export const apiUrl = `${API_URL}/api`;

export const fileApiUrl = `${apiUrl}/files`;

export const api = (url: string, options: object) => {
  return fetch(url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getTokens().token,
    },
  });
};

export const apiAxios = () =>
  axios.create({
    headers: {
      Authorization: "Bearer " + getTokens().token,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
