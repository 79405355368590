import React from "react";
import Icon from "@ant-design/icons";
import { ReactComponent as SpainSvg } from "../assets/icons/spain.svg";
import { ReactComponent as UnitedStates } from "../assets/icons/united-states.svg";
import { ReactComponent as Portuguese } from "../assets/icons/brazil.svg";
import { ReactComponent as France } from "../assets/icons/france.svg";
import { ReactComponent as Russia } from "../assets/icons/russia.svg";
import { ReactComponent as Sweden } from "../assets/icons/sweden.svg";
import { ReactComponent as China } from "../assets/icons/china.svg";
import { ReactComponent as SvgInterrogate } from "../assets/icons/circle_interrogate_icon.svg";
import { ReactComponent as BitcoinSvg } from "../assets/icons/bitcoin.svg";
import { ReactComponent as EthSvg } from "../assets/icons/eth.svg";
import { ReactComponent as UsdtSvg } from "../assets/icons/usdt.svg";
import { ReactComponent as IconUser } from "../assets/icons/user.svg";
import { ReactComponent as IconArrowL } from "../assets/icons/arrow-leftB.svg";
import { ReactComponent as IconArrowR } from "../assets/icons/arrow-right.svg";
import { ReactComponent as ChartLineSvg } from "../assets/icons/chart-line.svg";
import { ReactComponent as HandHoldingUsdSvg } from "../assets/icons/hand-holding-usd.svg";
import { ReactComponent as UserCenterSvg } from "../assets/icons/user-center.svg";

export const UserCenterIcon = (props: any) => (
  <Icon component={UserCenterSvg} {...props} />
);

export const HandHoldingUsdIcon = (props: any) => (
  <Icon component={HandHoldingUsdSvg} {...props} />
);

export const ChartLineIcon = (props: any) => (
  <Icon component={ChartLineSvg} {...props} />
);

export const EthIcon = (props: any) => <Icon component={EthSvg} {...props} />;

export const ArrowLeftIcon = (props: any) => (
  <Icon component={IconArrowL} {...props} />
);
export const ArrowRightIcon = (props: any) => (
  <Icon component={IconArrowR} {...props} />
);

export const UserIcon = (props: any) => (
  <Icon component={IconUser} {...props} />
);

export const UsdtIcon = (props: any) => <Icon component={UsdtSvg} {...props} />;

export const BitcoinIcon = (props: any) => (
  <Icon component={BitcoinSvg} {...props} />
);

export const FlagSpain = (props: any) => (
  <Icon component={SpainSvg} {...props} />
);

export const CircleIntegorragate = (props: any) => (
  <Icon component={SvgInterrogate} {...props} />
);

export const FlagChina = (props: any) => <Icon component={China} {...props} />;

export const FlagUnitedStates = (props: any) => (
  <Icon component={UnitedStates} {...props} />
);

export const FlagPortuguese = (props: any) => (
  <Icon component={Portuguese} {...props} />
);

export const FlagFrance = (props: any) => (
  <Icon component={France} {...props} />
);

export const FlagRussia = (props: any) => (
  <Icon component={Russia} {...props} />
);

export const FlagSweden = (props: any) => (
  <Icon component={Sweden} {...props} />
);
