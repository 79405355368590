import {
  OnUpdateNotification,
  OnUpdateNotificationError,
  OnFetchNotification,
  OnFetchNotificationError,
  OnFetchReadAllNotification,
  OnFetchReadAllNotificationError,
  OnReadMassiveNotification,
  OnReadMassiveNotificationError,
} from "./notifications-events";
import { handleError, handleResponse } from "./../../shared/utils";
import {
  updateNotification,
  fetchNotifications,
  fetchReadAllNotifications,
  readMassiveNotification,
} from "./notifications-services";

export const updateNotificationAction = async (id: number, status: number) => {
  let response;

  try {
    response = await updateNotification(id, status);
  } catch (error) {
    handleError(error);
    OnUpdateNotificationError.dispatch(error);
  }

  handleResponse(OnUpdateNotification, OnUpdateNotificationError, response);
};

export const readMassiveNotificationAction = async (id: number) => {
  let response;

  try {
    response = await readMassiveNotification(id);
  } catch (error) {
    handleError(error);
    OnReadMassiveNotificationError.dispatch(error);
  }

  handleResponse(
    OnReadMassiveNotification,
    OnReadMassiveNotificationError,
    response
  );
};

export const fetchNotificationsAction = async () => {
  let response;

  try {
    response = await fetchNotifications();
  } catch (error) {
    handleError(error);
    OnFetchNotificationError.dispatch(error);
  }

  handleResponse(OnFetchNotification, OnFetchNotificationError, response);
};

export const fetchReadAllNotificationsAction = async () => {
  let response;

  try {
    response = await fetchReadAllNotifications();
  } catch (error) {
    handleError(error);
    OnFetchReadAllNotificationError.dispatch(error);
  }

  handleResponse(
    OnFetchReadAllNotification,
    OnFetchReadAllNotificationError,
    response
  );
};
