import { Matrix, User } from "@devsplan/wealthclub-shared";
import { Event } from "@cobuildlab/react-simple-state/lib/event";
import i18n from "../i18n/i18n";
import { notification } from "antd";
import {
  EMAIL_KEY,
  REFRESH_TOKEN_KEY,
  SIGN_UP_ROUTE,
  TOKEN_KEY,
} from "./constants";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { apiUrl } from "../api";
import { OnFetchMe } from "../api/me/me-events";
import { NotificationVerifyEmail } from "../components";

export const setTokens = (token: string, refreshToken = "", email = "") => {
  localStorage.setItem(TOKEN_KEY, token);

  if (refreshToken !== "") {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  if (email !== "") {
    localStorage.setItem(EMAIL_KEY, email);
  }
};

export const getTokens = () => {
  return {
    token: localStorage.getItem(TOKEN_KEY),
    refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
    email: localStorage.getItem(EMAIL_KEY),
  };
};

export const removeTokens = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(EMAIL_KEY);
};

export const handleError = (error: any) => {
  console.error(error);
  notification.error({
    message: i18n.t("Error"),
    description: error.message,
    duration: 6,
  });
};

export const handleResponseError = (response: Response) => {
  if (!response.ok) {
    if (process.env.REACT_APP_ENV === "development") {
      handleError({
        message: response.statusText,
      });
    } else {
      handleError({
        message: i18n.t("A problem has occurred, please try again later"),
      });
    }
  }
};

export const handleResponse = async (
  event: Event<any>,
  eventError: Event<any>,
  response?: Response,
  cb?: any,
  extra?: any
) => {
  const messages: { [key: string]: string } = {
    "Auth-exists-email": i18n.t("This email is already in use"),
    "Auth-register-successful": i18n.t("Successful registration"),
    "Auth-data-incorrect": i18n.t("Incorrect data"),
    "Auth-email-unverified": i18n.t("Email unverified"),
    "Auth-access-denied": i18n.t("Access not allowed"),
    "Auth-initSession-successful": i18n.t("Login Successful"),

    "Balance-createDeposit-successful": i18n.t("Deposit created successfully"),
    "Balance-retreat-createSuccessful": i18n.t(
      "Withdrawal created successfully"
    ),
    "CryptoBalance-retreat-createSuccessful": i18n.t(
      "Withdrawal created successfully"
    ),
    "Balance-amount-required": i18n.t("You must enter an amount"),
    "Balance-defineMethod-payment": i18n.t(
      "You must configure your payment preferences"
    ),
    "Balance-safeBox": i18n.t("Your safe is locked"),
    "Balance-noFunds": i18n.t("You don't have enough funds"),

    "CryptoBalance-createDeposit-successful": i18n.t(
      "Deposit created successfully"
    ),
    "CryptoBalance-amount-required": i18n.t("You must enter an amount"),
    "CryptoBalance-defineMethod-payment": i18n.t(
      "You must configure your payment preferences"
    ),
    "CryptoBalance-safeBox": i18n.t("Your safe is locked"),
    "CryptoBalance-noFunds": i18n.t("You don't have enough funds"),

    "CryptoBalance-crypto-disabled": i18n.t("Crypto not active"),
    "CryptoBalance-withdraw-error": i18n.t("Error creating the withdrawal"),

    "cycle-create-successful": i18n.t("Cycle successfully created"),
    "Cycle-limit-reached": i18n.t("You cannot create more than 6 cycles"),
    "Cycle-not-found": i18n.t("Cycle not found"),
    "Cycle-ID-notSent": i18n.t("Cycle not found"),
    "Cycle-createReinvestment-successful": i18n.t(
      "Reinvestment created successfully"
    ),
    "Cycle-notReinvestment-still": i18n.t("Reinvestment not available yet"),

    "ForgotPasswor-linkSent-recoveryPassword": i18n.t("Link sent successfully"),
    "ForgotPasswor-link-invalid": i18n.t("Invalid link"),
    "ForgotPasswor-newPassword-successful": i18n.t(
      "Password changed successfully"
    ),

    "Investment-create-successful": i18n.t("Investment created successfully"),
    "Investment-delete-successful": i18n.t("Investment successfully removed"),
    "Investment-insufficient-funds": i18n.t("You don't have enough funds"),

    "Legacy-cycle-ID-notSent": i18n.t("Cycle not found"),
    "Legacy-cycle-createReinvestment-successful": i18n.t(
      "Reinvestment created successfully"
    ),
    "Legacy-cycle-not-found": i18n.t("Cycle not found"),
    "Legacy-insufficient-funds": i18n.t("You don't have enough funds"),
    "Legacy-investmentCreate-successful": i18n.t(
      "Investment created successfully"
    ),
    "Legacy-investmentDelete-successful": i18n.t(
      "Investment successfully removed"
    ),

    "Setting-preferencesCreate-successful": i18n.t("Data created successfully"),
    "Setting-preferencesUpdate-successful": i18n.t("Data updated successfully"),

    "User-notFound": i18n.t("User not found"),

    "Voucher-proofCreate-successful": i18n.t("Successfully uploaded voucher"),

    "CheckIsAdmin-unAuthorized": i18n.t("Not authorized"),

    "user-update-success": i18n.t("Profile saved successfully"),
    "user-update-error": i18n.t("Error updating profile"),
    "user-change-password-success": i18n.t("Password changed successfully"),
    "user-not-found": i18n.t("User not found"),

    "balance-deleted-success": i18n.t("Record successfully deleted"),

    "paypal-payment-success": i18n.t("Successful PayPal payment"),
    "paypal-payment-fail": i18n.t("Payment with PayPal failed"),

    "contact-created-success": i18n.t("Thank you for contacting us"),

    "ticket-already-exists": i18n.t(
      "You already have a ticket created, you can only create one at a time"
    ),

    "email-verified-success": i18n.t("Email verified"),
    "email-verified-fail": i18n.t("This link is invalid or has expired"),
    "email-verified-missing": i18n.t(
      "Please verify your email, we have sent you the verification email"
    ),
    "email-verified-pending": i18n.t("Please verify your email"),
    "email-verified-invalid": i18n.t(
      "This link is no longer valid to verify your email"
    ),
    "email-verification-sent": i18n.t(
      "We have sent a verification link to your email"
    ),
  };
  let json;

  if (response) {
    try {
      json = await response.json();
    } catch (error) {
      handleError(error);
    }

    if (response.ok) {
      cb && cb(json);
      event.dispatch(json.data);

      if (json.message) {
        notification.success({
          message: i18n.t("Success"),
          description: messages[json.message] || json.message,
          duration: 6,
        });
      }
    } else {
      if (json && json.message) {
        if (json.message === "Auth-email-unverified") {
          notification.error({
            message: i18n.t("Error"),
            description: NotificationVerifyEmail(
              messages[json.message],
              i18n.t("Re-send email"),
              i18n.t("Email sent!"),
              i18n.t("Error re-sending the email"),
              extra.email
            ),
            duration: 60,
          });
        } else {
          handleError({ message: messages[json.message] || json.message });
        }
      } else {
        handleResponseError(response);
      }

      eventError.dispatch(response.statusText);
    }
  }
};

export const payWithPayPal = (
  amount: number,
  code: string,
  type: "deposit" | "investment"
) => {
  const userInfo = OnFetchMe.get();

  if (userInfo) {
    window.location.replace(
      `${apiUrl}/paypal/pay?amount=${amount}&code=${code}&type=${type}&user_id=${userInfo.id}`
    );
  }
};

export const openPayPal = (code: string) => {
  window.open(
    `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${code}`,
    "_blank"
  );
};

export const totalInvestedNextLevel = (user: User) => {
  const totalLeft =
    (user?.next_plan?.investment || 0) - (user?.total_invested || 0);

  return totalLeft < 0 ? 0 : totalLeft;
};

export const getInvestmentHoursLeft = (createdAtDate: string) => {
  const now = moment();
  const createdAt = moment(createdAtDate);
  const hours = now.diff(createdAt, "hours");
  const hoursLeft = 48 - hours;

  return hoursLeft;
};

export const setBodyBg = (system: string): void => {
  document.body.classList.remove("legacy");
  document.body.classList.remove("legacy2");
  document.body.classList.remove("legacy4");
  document.body.classList.remove("gold");
  document.body.classList.remove("bitcoin");
  document.body.classList.remove("usdt");
  document.body.classList.remove("eth");
  document.body.classList.remove("realstate");
  document.body.classList.add(system.replace(".0", ""));
};

export const createReferralLink = (affiliate_code?: string) => {
  return `https://wealthclub.org${SIGN_UP_ROUTE}?affiliate_code=${affiliate_code}`;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const fixMatrixLevels = (matrix: Matrix[]): Matrix[] => {
  // console.log("Start fix levels");

  return matrix.map((matrixItem) => {
    const {
      index,
      // level,
      // children_index_0,
      // children_index_1
    } = matrixItem;
    let newIndex = index;

    // if (children_index_0 !== null && children_index_1 !== null) {
    //   if (index !== children_index_0 && index !== children_index_1) {
    //     // console.log(
    //     //   matrixItem.user_id,
    //     //   level,
    //     //   index,
    //     //   children_index_0,
    //     //   children_index_1
    //     // );

    //     const levelItem0 = matrix.find(
    //       (matrixItem) =>
    //         matrixItem.level === level &&
    //         matrixItem.children_index_0 === children_index_0
    //     );

    //     if (levelItem0 && levelItem0.user_id === matrixItem.user_id) {
    //       newIndex = children_index_0 || 0;
    //     } else {
    //       newIndex = children_index_1 || 0;
    //     }
    //   }
    // }

    // console.log("End fix levels");

    return {
      ...matrixItem,
      index: newIndex,
    };
  });
};

export const sortData = (data: any) => {
  return data.sort((x: any, y: any) => {
    const dateA = moment(x.created_at);
    const dateB = moment(y.created_at);

    return dateB.diff(dateA, "seconds");
  });
};

export const getUserBalances = (user?: User) => {
  if (!user) {
    return {
      balance: 0,
      earnings: 0,
      toInvest: 0,
      withdrawal: 0,
    };
  }

  let deposit =
    user.balance_deposit + user.balance_reinvested_gold + user.balance_invested;
  let earnings =
    user.balance_earning + user.balance_paid + user.balance_withdrawal;
  const balance = user.balance + Math.abs(user.balance_withdrawal);
  const withdrawal = Math.abs(user.balance_withdrawal);

  if (deposit < 0) {
    earnings = deposit + earnings;
    deposit = 0;
  }

  return {
    balance: balance <= 0 ? 0 : balance,
    toInvest: deposit <= 0 ? 0 : deposit,
    withdrawal: withdrawal || 0,
    earnings: earnings <= 0 ? 0 : earnings,
  };
};

/**
 * unescapeHTML - unescape html string to render as elements.
 *
 * @param {string} text
 * @returns {string} html unescaped.
 */
export const unescapeHTML = (text: string): string => {
  return unescape(text).replace(/&lt;/gm, "<").replace(/&gt;/gm, ">");
};

export const getYoutubeImage = (url: string) => {
  const videoId = url.split("v=")[1];

  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

// 1 BTC = 100.000.000 SATOSHIS
export const convertSatoshisToBtc = (satoshis: number): number =>
  satoshis / 100000000;

// 1 BTC = 100.000.000 SATOSHIS
export const convertBtcToSatoshis = (btc: number): number => btc * 100000000;

export const formatFriendlyBtc = (amount: number, decimals: number) => {
  return parseFloat(amount.toFixed(decimals));
};

export const getBitcoinAmountWithFee = (amount: number, percent = 3) => {
  const min_fee_amount = 0.00001;
  const percent_fee_amount = amount * (percent / 100);
  const total_fee_amount = percent_fee_amount + min_fee_amount;

  return {
    amount: amount,
    min_fee: min_fee_amount,
    percent_fee_amount: percent_fee_amount,
    amount_with_fee: amount + total_fee_amount,
    total_fee_amount: total_fee_amount,
  };
};
